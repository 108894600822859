import React, { useState } from "react";
import editicon from "../../Images/editicon.png";
import cameralogo from "../../Images/cameralogo.png";
import blmbennerimg from "../../Images/blmbennerimg.png";
import EditProfileblm from "./EditProfileblm";
import Editbannermodalbml from "../../Modal/Editbannermodalbml";
import Editbannerimagemodal from "../../Modal/Editbannerimagemodal";
import { useSelector } from "react-redux";
const Uploadbannerblm = ({ data, updateForm }) => {
  const { UserProfileID } = useSelector((state) => state.app);
  const [bannermodalaedi, setBannermodalaedi] = useState(false);
  const [editbannermoadal, setEditbannermoadal] = useState(false);
  const [url, setUrl] = useState(data?.businesBanner || "");
  const editbannermodalclick = () => {
    setBannermodalaedi(!bannermodalaedi);
  };
  const editbannerclcik = () => {
    setEditbannermoadal(!editbannermoadal);
  };
  return (
    <>
      <div className="uploadbannerblmdiv ">
        <div className="uploadbannerdivbg">
          {/* <div className="editeiconimgspclnmew" onClick={editbannerclcik}> */}
          {
            UserProfileID?._id === data?._id &&
            <div className="editeiconimgspclnmew">
              <img src={editicon} alt="..." onClick={editbannermodalclick} />
            </div>
          }


          {/* <div className="cameralogodivimgabslt" onClick={editbannermodalclick}> */}
          <div className="cameralogodivimgabslt" style={{
            width:'100%',
                height:'100%',
                maxWidth:'100%',
                minWidth:'100%',
                display:'flex',
                justifyContent:'center',
                alignItems:'center',
                padding:'0px'
             
    
          }}>
            {/* <div className='filedibbhsjj'>
                            <input type='file' className='filedibbhsjjdiflediv'/>
                        </div> */}
            {data?.businesBanner ? (
              <div className="cameralogodivimg" style={{
                width:'100%',
                height:'100%',
                maxWidth:'100%',
                minWidth:'100%',
              }}>
                <img
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    borderRadius: "10px",
                  }}
                  src={data?.businesBanner}
                  alt="..."
                />
              </div>
            ) : (
              <>
              <div>

             
                <div className="cameralogodivimg">
                  <img src={cameralogo} alt="..." />
                </div>
                <p className="uplsdttxttppshji">Upload Banner</p>
                <p className="pixelsttxttppio">
                  (Pixel Size : 000 x 000 PNG/JPEG )
                </p>
                </div>
              </>
            )}
          </div>

          {/* <div className="upoloafgsbabberfibommjge">
                    <img src={blmbennerimg} alt='...' />
                </div> */}
        </div>
      </div>
      {bannermodalaedi && (
        <Editbannermodalbml
          url={url}
          setUrl={setUrl}
          updateForm={updateForm}
          closemodal={setBannermodalaedi}
        />
      )}
      {editbannermoadal && (
        <Editbannerimagemodal closemodal={setEditbannermoadal} />
      )}
    </>
  );
};

export default Uploadbannerblm;
