import React from 'react'
import { useSelector } from 'react-redux';

const Upgradeprofile = () => {
  const { UserProfileID } = useSelector((state) => state.app);
  return (
    <div className='viewsandebgagemntdiv'>
       <div className='viewsandebgagemntdpaddinf'>
         <div className=''>
            <p className='meettscysttxtpp'>Meet customers smartly and boost business digitally.</p>
            <p className='wehelpsppeottxpp'>
            We help people connect with opportunities. <br/>
            Join our people accelerator business reward program.
            </p>
         </div>
         <div className='trtysttxnowtrtysttxndiv'>
            <button className='trtysttxnowttxtp'>Try Now</button>
            <button className='ipyysffraedtn' disabled={!UserProfileID?.businessStatus}>Upgrade</button>
         </div>
       </div>
    </div>
  )
}

export default Upgradeprofile
