import React, { useEffect, useState } from 'react'
import crosssimgiocn from "../../src/Images/crosssimgiocn.png";
import vectorarrow from "../../src/Images/vectorarrow.png"
import HttpClient from '../utils/HttpClient';
import Select from "react-select";
import { Autocomplete, TextField, Chip } from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import { fetchMyBusinessPosts } from '../Redux/Slice/BusinessPostSlice';

const selectStyles = {
    menu: (provided) => ({
        ...provided,
        zIndex: 2,
    }),

};

const BusinessPostModal = ({ closemodal }) => {
    const { UserProfileID } = useSelector((state) => state.app);
    const { status, MyBusinessPosts, } = useSelector((state) => state.post);
    const dispatch = useDispatch()

    const [selectedOptions, setSelectedOptions] = useState([])
    const [serviceOptions, setServiceOptions] = useState([])
    const [drowdowsncustom, setDrowdowsncustom] = useState(false)
    const drowdowncustclick = () => {
        setDrowdowsncustom(!drowdowsncustom)
    }

    const [ddlState, setDdlState] = useState(false)
    const ddlStateClick = () => {
        setDdlState(!ddlState)
    }
    const [ddlCountry, setDdlCountry] = useState(false)
    const ddlCountryClick = () => {
        setDdlCountry(!ddlCountry)
    }

    const initialPostData = {
        subject: "",
        description: "",
        file: "",
        targetAudience: [],
        searchKeywords: [],
        state: "",
        countryId: "65f95f070d6753c3bdacccd9"
    };
    const [formData, setFormData] = useState(initialPostData);
    const [countryStates, setCountryStates] = useState([]);
    const [countries, setCountries] = useState([]);

    const getCountryStates = async () => {
        try {
            const res = await HttpClient.requestData(
                "countrywise-state/65f95f070d6753c3bdacccd9",
                "GET"
            );
            if (res?.status) {
                setCountryStates(res.data?.[0]?.states || []);
            }
        } catch (error) {
            console.error("Error fetching country states:", error);
        }
    };

    const getCountries = async () => {
        try {
            const response = await HttpClient.requestData("countries-list", "GET");
            if (response.status) {
                setCountries(response.data);
            }
        } catch (error) {
            console.error("Error fetching countries:", error);
        }
    };

    const getCompanyTypeWiseServices = async (id) => {
        try {
            const res = await HttpClient.requestData(
                `companyType-wise-servicess/${id}`,
                "GET"
            );

            if (res?.status) {
                console.log("RES46E", res.data, UserProfileID?.businessProductCat);

                const filteredData = res.data.filter(
                    // (item) => !UserProfileID?.businessProductCat.includes(item._id)
                    //   (item) => !services.includes(item._id)
                    (item) => item
                );

                setServiceOptions(filteredData.map((item) => ({
                    value: item._id,
                    label: item.name,
                })) || []);
            }
        } catch (error) {
            console.error("Error occured!", error);
        }
    };


    const [selectedKeywordOptions, setSelectedKeywordOptions] = useState([]);
    const handleChange = (event, newValue) => {
        setSelectedKeywordOptions(newValue);
    };
    const [keywordOptions, setKeywordOptions] = useState([])
    const getBusinessKeywords = async () => {
        try {
            const res = await HttpClient.requestData(
                "business-product-keywords",
                "GET"
            );

            if (res?.status) {
                const filteredData = res.data.filter(
                    // (item) => !UserProfileID?.businessProductkeywords.includes(item._id)
                    // (item) => !services.includes(item._id)
                    (item) => item
                );
                setKeywordOptions(filteredData || [])
            }
        } catch (error) {
            console.error("Error fetching country states:", error);
        }
    };

    useEffect(() => {
        getCompanyTypeWiseServices(UserProfileID?.companyTypeID)
        getBusinessKeywords()
        getCountries();
        getCountryStates();
    }, []);

    const handleServiceChange = (selected) => {
        setSelectedOptions(selected);
    };

    const updateForm = (name, value) => {
        setFormData((prev) => ({ ...prev, [name]: value }));
    };


    console.log("FormData66", formData);

    const [fileName, setFileName] = useState("");

    const imageUpload = async (file) => {
        if (!file) return; // Ensure there is a file to upload

        // setLoading(true);
        const formData = new FormData();
        formData.append("image", file);

        try {
            console.log("Uploading image:", file);
            // const res = await HttpClient.fileUplode("image-upload", "POST", formData);
            const res = await HttpClient.fileUploadWithoutAuth("image-upload", "POST", formData);
            if (res.status) {
                console.log("Image uploaded successfully:", res);
                // setData(res.data.url);
                updateForm("file", res.data.url)
                // setHasData(true);
            } else {
                console.log("Error while uploading image");
            }
        } catch (error) {
            console.error("Upload failed:", error);
        } finally {
            // setLoading(false);
        }
    };

    const [uploadedFilePreview, setUploadedFilePreview] = useState("No file chosen")
    const handleFileChange = (event) => {
        if (event.target.files && event.target.files.length > 0) {
            setFileName(event.target.files[0].name);
            // setUploadedFilePreview(event.target.files[0].name.split('.').pop().toLowerCase())
            setUploadedFilePreview(event.target.files[0].name)
            imageUpload(event.target.files[0]);
        } else {
            // setFileName("No file chosen");
            setUploadedFilePreview("No file chosen")
        }
    };



    const handleSubmit = async (e) => {
        e.preventDefault()
        const data = {
            ...formData,
            targetAudience: selectedOptions.map((item) => item.label),
            targetAudienceId: selectedOptions.map((item) => item.value),
            searchKeywords: selectedKeywordOptions.map((item) => item.keyword),
            searchKeywordsId: selectedKeywordOptions.map((item) => item._id),
        }
        // console.log("formData186", data);
        // return

        try {
            const response = await HttpClient.requestData(
                "post-upload",
                "POST",
                data
            );
            if (response.status) {
                // Navigate to payment page
                // Navigate("/searchhome");
                // Navigate("/");
                // dispatch(getOwnProfile())

                // console.log("RESP", response);
                // closemodal()

            } else {
                console.error("Error in Post");
                console.log("RESP", response);
            }
        } catch (error) {
            console.error("Error submitting Post:", error);
            console.log("RESP", error);
        } finally {
            dispatch(fetchMyBusinessPosts())
            closemodal()
        }
    }


    return (
        <div className='uploabannermodalbgshadow'>
            <div className='enquerysmokdnndbndbg'>
                <div className="threedorttsdcoroodivflx">
                    <div className="imnmnprhhhsbdijnvvgbsj" onClick={() => closemodal()}>
                        <img src={crosssimgiocn} alt="..." />
                    </div>
                </div>

                <div class="psendwendijndwjjwndiv">
                    <p class="breyuuayproosttxppp">Post your updates</p>

                    <div className='poysttdiobnnnmmmnjm'>
                        <p className='conattaindptxtppnewallasa'>Subject <span> </span></p>
                        <input type="text" placeholder="Enter a subject for your post" className="inpustxocntbusuitxtppnewallattyy"
                            value={formData.subject || ""}
                            onChange={(e) => updateForm("subject", e.target.value)}
                        />
                    </div>


                    <div className='poysttdiobnnnmmmnjm'>
                        <p className='conattaindptxtppnewallasa'>Description <span> </span></p>
                        <textarea rows={4} cols={4} className='ennsjjuwtysttsxttaytredg'
                            placeholder="Enter description..."
                            value={formData.description || ""}
                            onChange={(e) => updateForm("description", e.target.value)}
                        />
                    </div>

                    {/* <div className='poysttdiobnnnmmmnjm'>
                        <p className='conattaindptxtppnewallasa'>Attachment <span>  </span></p>
                        <div className='chossefilkklkebdtrfdiv'>
                            <div className='enuqteyschosefllfbrdttdivdkdlnb'>
                                <button className='enuqteyschosefllfbrdtt'>
                                    Choose file
                                </button>
                                <p>No file chosen</p>
                            </div>
                        </div>
                    </div> */}

                    <div className='poysttdiobnnnmmmnjm'>
                        <p className='conattaindptxtppnewallasa'>Attachment <span>  </span></p>
                        <div className='chossefilkklkebdtrfdiv'>
                            <div className='enuqteyschosefllfbrdttdivdkdlnb'>
                                <div class="custom-file-upload">
                                    <label for="file-upload" class="file-label">
                                        Choose file
                                    </label>
                                    <input id="file-upload" type="file" onChange={handleFileChange} />
                                    {/* <span id="file-chosen">{fileName}</span> */}
                                    <span id="file-chosen">{uploadedFilePreview}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='poysttdiobnnnmmmnjm'>
                        <p className='conattaindptxtppnewallasa'>Type of busienss <span>  </span></p>
                        {/* <div className='compamyupdatepoksttmodaldiv'>

                            <div className='compamydinfodivmdiobbdgppshgsadd'>

                                <div className='dropwdowndivfrmbusineenewwlas'>
                                    <p className='vectoraarowdivomgulmaili'>
                                        Advertising
                                    </p>
                                    <div className='vectoraarowdivomgnenwlal' onClick={drowdowncustclick}>
                                        <img src={vectorarrow} alt='...' />
                                    </div>
                                    {drowdowsncustom && <div className='vectoraarowdivomgulmai'>
                                        <ul className='vectoraarowdivomgulmaiul'>
                                            <li className='vectoraarowdivomgulmaili'>
                                                Advertising
                                            </li>
                                            <li className='vectoraarowdivomgulmaili'>
                                                Advertising
                                            </li>
                                            <li className='vectoraarowdivomgulmaili'>
                                                Advertising
                                            </li>
                                            <li className='vectoraarowdivomgulmaili'>
                                                Advertising
                                            </li>
                                            <li className='vectoraarowdivomgulmaili'>
                                                Advertising
                                            </li>
                                        </ul>
                                    </div>}
                                </div>
                            </div>

                            <div className='compamydinfodivmdiobbdgppshgsadd'>

                                <div className='dropwdowndivfrmbusineenewwlas'>
                                    <p className='vectoraarowdivomgulmaili'>
                                        Digital marketing
                                    </p>
                                    <div className='vectoraarowdivomgnenwlal' onClick={drowdowncustclick}>
                                        <img src={vectorarrow} alt='...' />
                                    </div>
                                    {drowdowsncustom && <div className='vectoraarowdivomgulmai'>
                                        <ul className='vectoraarowdivomgulmaiul'>
                                            <li className='vectoraarowdivomgulmaili'>
                                                Digital marketing
                                            </li>
                                            <li className='vectoraarowdivomgulmaili'>
                                                Digital marketing
                                            </li>
                                            <li className='vectoraarowdivomgulmaili'>
                                                Digital marketing
                                            </li>
                                            <li className='vectoraarowdivomgulmaili'>
                                                Digital marketing
                                            </li>
                                            <li className='vectoraarowdivomgulmaili'>
                                                Digital marketing
                                            </li>
                                        </ul>
                                    </div>}
                                </div>
                            </div>

                            <div className='compamydinfodivmdiobbdgppshgsadd'>

                                <div className='dropwdowndivfrmbusineenewwlas'>
                                    <p className='vectoraarowdivomgulmaili'>
                                        Digital marketing
                                    </p>
                                    <div className='vectoraarowdivomgnenwlal' onClick={drowdowncustclick}>
                                        <img src={vectorarrow} alt='...' />
                                    </div>
                                    {drowdowsncustom && <div className='vectoraarowdivomgulmai'>
                                        <ul className='vectoraarowdivomgulmaiul'>
                                            <li className='vectoraarowdivomgulmaili'>
                                                Digital marketing
                                            </li>
                                            <li className='vectoraarowdivomgulmaili'>
                                                Digital marketing
                                            </li>
                                            <li className='vectoraarowdivomgulmaili'>
                                                Digital marketing
                                            </li>
                                            <li className='vectoraarowdivomgulmaili'>
                                                Digital marketing
                                            </li>
                                            <li className='vectoraarowdivomgulmaili'>
                                                Digital marketing
                                            </li>
                                        </ul>
                                    </div>}
                                </div>
                            </div>
                        </div> */}
                        <Select
                            options={serviceOptions}
                            isMulti
                            value={selectedOptions}
                            onChange={handleServiceChange}
                            placeholder="Select type of busienss..."
                            styles={selectStyles}
                        />
                    </div>


                    <div className='poysttdiobnnnmmmnjm'>
                        <p className='conattaindptxtppnewallasa'>Search keywords <span>  </span></p>
                        {/* <div className='compamyupdatepoksttmodaldiv'>
                            <div className='compamydinfodivmdiobbdgppshgsadd'>

                                <input type="text" placeholder="Advertising" className="inpustxocntbusuitxtppnewallattyy" />
                            </div>
                            <div className='compamydinfodivmdiobbdgppshgsadd'>

                                <input type="text" placeholder="Advertising" className="inpustxocntbusuitxtppnewallattyy" />
                            </div>
                            <div className='compamydinfodivmdiobbdgppshgsadd'>

                                <input type="text" placeholder="Digital marketing" className="inpustxocntbusuitxtppnewallattyy" />
                            </div>


                        </div> */}
                        <Autocomplete
                            multiple
                            options={keywordOptions}
                            getOptionLabel={(option) => option.keyword}
                            value={selectedKeywordOptions}
                            onChange={handleChange}
                            isOptionEqualToValue={(option, value) => option._id === value._id}
                            renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                    <Chip
                                        key={option.id}
                                        label={option.keyword}
                                        {...getTagProps({ index })}
                                    />
                                ))
                            }
                            renderInput={(params) => (
                                <TextField {...params} label="Select keywords" placeholder="Type to search" />
                            )}
                        />
                    </div>

                    <div className='poysttdiobnnnmmmnjm'>
                        <div className='poysttdiobnnnmmmnjmdivflxs'>
                            <div className='compamydinfodivmdiobbdgppshg'>
                                <p className='conattaindptxtppnewallasa'>State <span> * </span></p>
                                <div className='dropwdowndivfrmbusineenewwlas'>
                                    {
                                        formData.state ? (
                                            <p className='vectoraarowdivomgulmaili'>
                                                {formData.state}
                                            </p>
                                        ) : (
                                            <p className='vectoraarowdivomgulmaili'>
                                                --- Choose ---
                                            </p>
                                        )
                                    }

                                    <div className='vectoraarowdivomgnenwlal' onClick={ddlStateClick}>
                                        <img src={vectorarrow} alt='...' />
                                    </div>
                                    {ddlState && <div className='vectoraarowdivomgulmai'>
                                        <ul className='vectoraarowdivomgulmaiul'>
                                            {countryStates.map((state, index) => (
                                                <li
                                                    key={index}
                                                    className="vectoraarowdivomgulmaili"
                                                    onClick={(e) => {
                                                        // handleInputChange("state", state);
                                                        // toggleDropdown("state");
                                                        updateForm("state", state?.name)
                                                        ddlStateClick()
                                                    }}
                                                >
                                                    {state?.name}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>}
                                </div>
                            </div>

                            <div className='compamydinfodivmdiobbdgppshg'>
                                <p className='conattaindptxtppnewallasa'>Country <span> * </span></p>
                                <div className='dropwdowndivfrmbusineenewwlas'>
                                    <p className='vectoraarowdivomgulmaili'>
                                        United arab emirate
                                    </p>
                                    <div className='vectoraarowdivomgnenwlal' onClick={drowdowncustclick}>
                                        <img src={vectorarrow} alt='...' />
                                    </div>
                                    {ddlCountry && <div className='vectoraarowdivomgulmai'>
                                        <ul className='vectoraarowdivomgulmaiul'>
                                            {countries.filter((item) => item._id === "65f95f070d6753c3bdacccd9").map((country, index) => (
                                                <li
                                                    key={index}
                                                    className="vectoraarowdivomgulmaili"
                                                    onClick={() => {
                                                        // handleInputChange("state", state);
                                                        // toggleDropdown("state");
                                                        updateForm("countryId", country._id)
                                                        ddlCountryClick()
                                                    }}
                                                >
                                                    {country?.name}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="svavtatvvbtnnmdivmaokin">
                        <button className="svavtatvvbtnnmsendferdgf"
                            onClick={(e) => handleSubmit(e)}
                        >Post</button>
                    </div>
                </div>






            </div>
        </div>
    )
}

export default BusinessPostModal
