import React, { useContext, useEffect, useState } from "react";
import EditProfileblm from "./EditProfileblm";
import { Colorcontext } from "../../Component/Colorcontext/ColorContext";
import Blmpostadd from "./Blmpostadd";
import Viewersengagement from "./Viewersengagement";
import Upgradeprofile from "./Upgradeprofile";
import Contactdetaiilsprofile from "./Contactdetaiilsprofile";
import Uploadbannerblm from "./Uploadbannerblm";
import Aboutusblmprofile from "./Aboutusblmprofile";
import Servicesblmprofile from "./Servicesblmprofile";
import Socilallinkprofile from "./Socilallinkprofile";
import Galleryblmprofile from "./Galleryblmprofile";
import Workprogressbar from "./Workprogressbar";
import Searchkeywordprofile from "./Searchkeywordprofile";
import { Navigate, useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getOwnProfile, getSingleUserProfile, UserLocation } from "../../Redux/Slice/PostSlice";
import HttpClient from "../../utils/HttpClient";
import toast from "react-hot-toast";

const UserProfile = () => {
  // const colordata = useContext(Colorcontext);
  const params = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const { SearchBar, ProfileSubmenuDropDownToggle, UserProfileID, SingleUserData } =
    useSelector((state) => state.app);

  const { choosePlanID } = location.state || {};
  const [UserMemberData, setUserMemberData] = useState({});
  const [data, setData] = useState();

  // console.log("LINE31S", UserProfileID, SingleUserData, params);



  // const [formData, setFormData] = useState({
  //   subscriptionId:choosePlanID,
  //   personName: UserProfileID?.personName,
  //   designationID: UserProfileID?.designationID,
  //   companyTypeID: UserProfileID?.companyTypeID,
  //   mobileNo: UserProfileID?.mobileNo,
  //   telephoneNo: UserProfileID?.telephoneNo,
  //   emailId: UserProfileID?.emailId,
  //   address: UserProfileID?.address,
  //   emirate: UserProfileID?.emirate,
  //   companyName: UserProfileID?.companyName,
  //   webAddress: UserProfileID?.webAddress,
  //   location: UserProfileID?.location,
  //   country: "65f95f070d6753c3bdacccd9"
  // });

  // const [formData, setFormData] = useState(UserProfileID);
  const [formData, setFormData] = useState();

  // console.log("LINE50", choosePlanID);







  const getUserProfile = async () => {
    // setisLoading(true);
    const res = await HttpClient.requestData("get-profile", "GET", {});
    // console.log(res?.data, "userprofile");
    if (res && res?.status) {
      // setisLoading(false);
      const updatedProfileData = {
        ...res?.data,
        // aboutBusiness: res?.data.companyDescription,
        subscriptionId: choosePlanID,
      };
      // setData(updatedProfileData);
      // dispatch(UserLocation(res?.data));
      // dispatch(UserProfileIDget(res?.data?._id));
    } else {
      // setisLoading(false);
    }
  };

  const getUserProfileById = async () => {
    // setisLoading(true);

    const res = await HttpClient.requestData(`single-user/${SingleUserData[0]?._id}`, "GET", {});
    console.log("LINE91", res?.data);

    if (res && res?.status) {
      // setisLoading(false);
      const updatedProfileData = {
        ...res?.data[0],
        // aboutBusiness: res?.data[0].companyDescription,
        // subscriptionId:choosePlanID,
      };
      setData(updatedProfileData);
      // dispatch(UserLocation(res?.data));
      // dispatch(UserProfileIDget(res?.data?._id));
    } else {
      // setisLoading(false);
    }
  };

  // useEffect(() => {
  //   params.name !== "dashboard" && dispatch(getSingleUserProfile(params.name))
  // }, [])

  useEffect(() => {
    // savedContactList();
    // params.name !== "undefined" && params.name !== "new" ? getUserProfileByName() : getUserProfile()

    // params.name !== "dashboard" ? setData(SingleUserData[0]) : setData(UserProfileID)
    // params.name !== "dashboard" ? getUserProfileById() : setData(UserProfileID)

    UserProfileID?.companyName?.toLowerCase().replace(/\s+/g, "_") !== params?.name ? getUserProfileById() : setData(UserProfileID)
    // UserProfileID?.companyName?.toLowerCase().replace(/\s+/g, "_") !== params?.name ? setData(SingleUserData[0]) : setData(UserProfileID)



    // console.log("LINE113", data, UserProfileID, SingleUserData);

  }, [UserProfileID, params]);


  // console.log("LINE122D", data);


  // useEffect(() => {
  //   colordata.setcolor(true);
  //   return () => {
  //     colordata.setcolor(false);
  //   };
  // }, []);

  useEffect(() => {
    console.log("RESP", data, UserProfileID);
    UserProfileID?.businessStatus && data !== undefined && updateData(data)
    // updateData(data)
  }, [data])

  // console.log("LINE29", UserProfileID?.memberStatus);

  const updateData = async (data) => {
    const updatedBusinessData = {
      ...data,
      country: "63dcc310811c87e00cd3a614",
    };

    // Remove properties
    delete updatedBusinessData._id;
    delete updatedBusinessData.password;
    delete updatedBusinessData.token;
    delete updatedBusinessData.SLNo;
    delete updatedBusinessData.TypeOfCompany;
    delete updatedBusinessData.avgRating;
    delete updatedBusinessData.designation;
    delete updatedBusinessData.doc;
    delete updatedBusinessData.likeCount;
    delete updatedBusinessData.likes;
    delete updatedBusinessData.loginStatus;
    delete updatedBusinessData.memberStatus;
    delete updatedBusinessData.partnerStatus;
    delete updatedBusinessData.passwordView;
    delete updatedBusinessData.productData;
    delete updatedBusinessData.rating;
    delete updatedBusinessData.referralStatus;
    delete updatedBusinessData.service;
    delete updatedBusinessData.serviceID;
    delete updatedBusinessData.serviceName;
    delete updatedBusinessData.upgradeStatus;
    delete updatedBusinessData.activationCode;
    delete updatedBusinessData.companyDescription;
    delete updatedBusinessData.companyTypeName;
    delete updatedBusinessData.dribbbleLink;
    delete updatedBusinessData.gender;
    delete updatedBusinessData.phone_code;
    delete updatedBusinessData.qrCode;
    delete updatedBusinessData.rewardPoints;
    delete updatedBusinessData.updatedOn;
    delete updatedBusinessData.userLink;
    delete updatedBusinessData.userName;
    delete updatedBusinessData.userType;
    delete updatedBusinessData.userTypeName;
    delete updatedBusinessData.videoUrl;
    delete updatedBusinessData.walletPoints;
    delete updatedBusinessData.websiteLink;
    delete updatedBusinessData.whats_code;
    delete updatedBusinessData.youtubeLink;


    console.log("LINE193P",updatedBusinessData);

    try {
      const response = await HttpClient.requestData(
        `update-business/${UserProfileID?._id}`,
        "PUT",
        updatedBusinessData
      );
      if (response.status) {
        // Navigate to payment page
        // Navigate("/searchhome");
        // Navigate("/");
        // dispatch(getOwnProfile())
        // toast.success("Updated Successfully!")
        console.log("RESP", response, data);

      } else {
        console.error("Error from listing business");
        console.log("RESP", response, data);
      }
    } catch (error) {
      console.error("Error submitting data:", error);
      console.log("RESP", error, data);
    } finally {
    }
  }

  const updateForm = (key, value) => {
    setData((prev) => ({ ...prev, [key]: value }));
  };


  return (
    <>
      <section className="ownblmprofiledivmaoin "
        style={{
          paddingTop: "15px"
        }}
      >
        <div className="cust_container">
          {
            !UserProfileID?.businessStatus && UserProfileID?._id === data?._id &&
            <div className="Workprogressbarmainb">
              <Workprogressbar data={data} page={3} />
            </div>
          }


          <div className=" reposivecideforspecial">
            <div className="ownblmprofiledivleft">
              <div className="repsonsivedivcls">
                {/* For Tablet responsive code start  */}
                <div className=" oenblmsnfidivmainleft">
                  <div className="oenblmsnfdrpbapbbtm responsivdedivmain">
                    <EditProfileblm updateForm={updateForm} data={data} />
                  </div>

                  <div className="oenblmsnfdrpbapbbtm responsivdedivmain">
                    <Contactdetaiilsprofile
                      setData={setData}
                      data={data}
                      updateForm={updateForm}
                    />
                  </div>
                </div>
                <div className="oenblmsnfidivmainright">
                  {
                    UserProfileID?._id === data?._id &&
                    <div className="oenblmsnfdrpbapbbtm responsivdedivmain ">
                    <Blmpostadd />
                  </div>
                  }
                  
                  {
                    UserProfileID?._id === data?._id &&
                    <div className="oenblmsnfdrpbapbbtm responsivdedivmain">
                      <Viewersengagement />
                    </div>
                  }

                  <div className="oenblmsnfdrpbapbbtm responsivdedivmain ">
                    <Upgradeprofile />
                  </div>
                  <div className="oenblmsnfdrpbapbbtm responsivdedivmain">
                    <Uploadbannerblm data={data} updateForm={updateForm} />
                  </div>
                </div>
                {/* For Tablet responsive code  end */}
              </div>

              <div className="oenblmsnfdrpbapbbtm deslrtopwdijjsmain">
                <EditProfileblm updateForm={updateForm} data={data} />
              </div>

              {
                UserProfileID?._id === data?._id &&
                <div className="oenblmsnfdrpbapbbtm deslrtopwdijjsmain">
                  <Blmpostadd />
                </div>
              }

              {
                UserProfileID?._id === data?._id &&
                <div className="oenblmsnfdrpbapbbtm deslrtopwdijjsmain">
                  <Viewersengagement />
                </div>
              }

              {
                UserProfileID?._id === data?._id &&
                <div className="oenblmsnfdrpbapbbtm deslrtopwdijjsmain">
                  <Upgradeprofile />
                </div>
              }

              <div className="oenblmsnfdrpbapbbtm deslrtopwdijjsmain">
                <Contactdetaiilsprofile
                  setData={setData}
                  data={data}
                  updateForm={updateForm}
                />
              </div>
            </div>
            <div className="ownblmprofiledivright">
              <div className="oenblmsnfdrpbapbbtm deslrtopwdijjsmain">
                <Uploadbannerblm data={data} updateForm={updateForm} />
              </div>
              <div className="oenblmsnfdrpbapbbtm">
                <Aboutusblmprofile data={data} updateForm={updateForm} />
              </div>

              <div className="oenblmsnfdrpbapbbtm">
                <Servicesblmprofile
                  data={data}
                  updateForm={updateForm}
                  setData={setData}
                />
              </div>

              {
                UserProfileID?._id === data?._id &&
                <div className="oenblmsnfdrpbapbbtm">
                  <Searchkeywordprofile data={data} updateForm={updateForm} />
                </div>
              }


              <div className="oenblmsnfdrpbapbbtm">
                <Galleryblmprofile
                  setData={setData}
                  data={data}
                  updateForm={updateForm}
                />
              </div>
              <div className="oenblmsnfdrpbapbbtm">
                <Socilallinkprofile updateForm={updateForm} data={data} />
              </div>
            </div>
          </div>

          {/* Mobile Screen Design Start */}

          <div className="mobilereposiveprofile">
            <div className="bannerprofileblmreposnive">
              <Uploadbannerblm data={data} updateForm={updateForm} />
            </div>
            <div className="mobilereposvispsicaldivflx">
              <div className="eidteprofileblmreposnive">
                <EditProfileblm updateForm={updateForm} data={data} />
              </div>

              <div className="viewsprofileprofileblmreposnive">
                <div className="">
                  <Viewersengagement />
                </div>
                <div className="bklmlposprofileprofileblmreposnive">
                  <Blmpostadd />
                </div>

                <div className="">
                  <Upgradeprofile />
                </div>
              </div>
            </div>
            <div className="contaallprofileblmreposnive">
              <Contactdetaiilsprofile
                setData={setData}
                data={data}
                updateForm={updateForm}
              />
            </div>
            <div className="contaallprofileblmreposnive">
              <Aboutusblmprofile data={data} updateForm={updateForm} />
            </div>
            <div className="contaallprofileblmreposnive">
              <Servicesblmprofile data={data} updateForm={updateForm} />
            </div>
            <div className="contaallprofileblmreposnive">
              <Galleryblmprofile
                setData={setData}
                data={data}
                updateForm={updateForm}
              />
            </div>
            <div className="contaallprofileblmreposnive">
              <Socilallinkprofile updateForm={updateForm} data={data} />
            </div>
          </div>

          {/* Mobile Screen Design End */}
        </div>
      </section>
    </>
  );
};

export default UserProfile;
