import React, { useState, useEffect } from "react";
import vectorarrow from "../../src/Images/vectorarrow.png";
import HttpClient from "../utils/HttpClient";

const Companyinfoeditmodal = ({ closemodal, data, setData, updateForm }) => {
  const [formData, setFormData] = useState(data);
  const [countryStates, setCountryStates] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [countries, setCountries] = useState([]);
  const [designations, setDesignations] = useState([]);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [toggleDesignation, setToggleDesignation] = useState(false);
  const [companyName, setCompanyName] = useState(null);
  const [toggleCompany, setToggleCompany] = useState(false);
  const [errors, setErrors] = useState({});
  const [designationName, setDesignationName] = useState(null);
  useEffect(() => {
    getCountryStates();
    getCompanies();
    getCountries();
    getDesignations();
  }, []);

  const getCountryStates = async () => {
    try {
      const res = await HttpClient.requestData(
        "countrywise-state/65f95f070d6753c3bdacccd9",
        "GET"
      );
      if (res?.status) {
        setCountryStates(res.data?.[0]?.states || []);
      }
    } catch (error) {
      console.error("Error fetching country states:", error);
    }
  };

  const getCompanies = async () => {
    try {
      const response = await HttpClient.requestData("companyType", "GET");
      if (response.status) {
        console.log("responseData : ", response?.data);
        setCompanies(response.data);
      }
    } catch (error) {
      console.error("Error fetching companies:", error);
    }
  };

  const getCountries = async () => {
    try {
      const response = await HttpClient.requestData("countries-list", "GET");
      if (response.status) {
        setCountries(response.data);
      }
    } catch (error) {
      console.error("Error fetching countries:", error);
    }
  };

  const validate = () => {
    let isValid = true;
    const error = {};

    if (!formData?.personName) {
      error.personName = "Please enter person name";
      isValid = false;
    }

    if (!formData?.designationID) {
      error.designationID = "Please select designation";
      isValid = false;
    }

    if (!formData?.companyTypeID) {
      error.companyTypeID = "Please select company";
      isValid = false;
    }

    if (!formData?.companyName) {
      error.companyName = "Please enter company name";
      isValid = false;
    }

    if (!formData?.mobileNo) {
      error.mobileNo = "Please enter mobile number";
      isValid = false;
    }

    setErrors(error);
    return isValid;
  };

  const submit = () => {
    if (validate()) {
      setData({
        ...data,
        ...formData,
      });
      closemodal();
    }
  };

  const getDesignations = async () => {
    try {
      const response = await HttpClient.requestData("designation", "GET");
      if (response.status) {
        setDesignations(response.data);
      }
    } catch (error) {
      console.error("Error fetching designations:", error);
    }
  };

  const handleInputChange = (field, value) => {
    setFormData((prevData) => ({ ...prevData, [field]: value }));
  };

  const toggleDropdown = (dropdown) => {
    setActiveDropdown(activeDropdown === dropdown ? null : dropdown);
  };

  useEffect(() => {
    if (designations && data?.designationID) {
      const foundDesignation = designations.find(
        (item) => item?._id === data?.designationID
      );
      setDesignationName(foundDesignation?.designation || null);
    }
  }, [designations, data]);

  useEffect(() => {
    if (companies && data?.companyTypeID) {
      const foundCompanies = companies.find(
        (item) => item?._id === data?.companyTypeID
      );
      console.log("foundCompanies : ", foundCompanies);
      setCompanyName(foundCompanies?.type || null);
    }
  }, [companies, data]);

  const renderDropdown = (items, field, placeholder) => (
    <div className="dropwdowndivfrmbusineenewwlas">
      <p className="vectoraarowdivomgulmaili">
        {formData[field] || placeholder}
      </p>
      <div
        className="vectoraarowdivomgnenwlal"
        onClick={() => toggleDropdown(field)}
      >
        <img src={vectorarrow} alt="Toggle dropdown" />
      </div>
      {activeDropdown === field && (
        <div className="vectoraarowdivomgulmai">
          <ul className="vectoraarowdivomgulmaiul">
            {items.map((item, index) => (
              <li
                key={index}
                className="vectoraarowdivomgulmaili"
                onClick={() => {
                  handleInputChange(field, item);
                  toggleDropdown(field);
                }}
              >
                {item}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );

  return (
    <div className="uploabannermodalbgshadow">
      <div className="compamyinfoeditbgmain">
        <div className="crossdivicon" onClick={() => closemodal()}>
          <i className="fa-solid fa-xmark"></i>
        </div>
        <div className="eidutcommhifnnfodivdibvamain">
          <div className="eidutcommhifnnfodiv">
            <h4 className="upldbannertrtxtpp">Edit Company information</h4>
            <div className="compamydinfodivmain">
              <div className="compamydinfodivmainbtm">
                <p className="conattaindptxtppnewallasa">
                  Full name <span>*</span>
                </p>
                <input
                  type="text"
                  placeholder="John Doe"
                  name="personName"
                  className="inpustxocntbusuitxtppnewallattyy"
                  value={formData.personName || ""}
                  onChange={(e) =>
                    handleInputChange("personName", e.target.value)
                  }
                />
                {errors.personName && (
                  <span className="error">{errors.personName}</span>
                )}
              </div>
              <div className="compamydinfodivmainbtm">
                <p className="conattaindptxtppnewallasa">
                  {" "}
                  Designation <span> *</span>
                </p>
                <div className="dropwdowndivfrmbusineenewwlas">
                  {formData?.designationID ? (
                    <p
                      style={{
                        color: "#000",
                        fontWeight: "500",
                      }}
                      className="vectoraarowdivomgulmaili"
                    >
                      {designationName}
                    </p>
                  ) : (
                    <p className="vectoraarowdivomgnenwlal">
                      Select Designation
                    </p>
                  )}
                  <div
                    className="vectoraarowdivomg"
                    onClick={() => setToggleDesignation(!toggleDesignation)}
                  >
                    <img
                      src={vectorarrow}
                      style={{
                        cursor: "pointer",
                      }}
                      alt="..."
                    />
                  </div>
                  {toggleDesignation && (
                    <div className="vectoraarowdivomgulmai">
                      <ul className="vectoraarowdivomgulmaiul">
                        {designations.map((item, index) => {
                          return (
                            <li
                              className="vectoraarowdivomgulmaili"
                              key={index}
                              style={{
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                handleInputChange("designationID", item?._id);
                                setDesignationName(item?.designation);
                                setToggleDesignation(!toggleDesignation);
                              }}
                            >
                              {item?.designation}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  )}
                </div>
                {errors.designationID && (
                  <span className="error">{errors.designationID}</span>
                )}
              </div>
              <div className="compamydinfodivmainbtm">
                <p className="conattaindptxtppnewallasa">
                  Company name <span>*</span>
                </p>
                <input
                  type="text"
                  placeholder="Company name"
                  className="inpustxocntbusuitxtppnewallattyy"
                  value={formData.companyName || ""}
                  onChange={(e) =>
                    handleInputChange("companyName", e.target.value)
                  }
                />
                {errors.companyName && (
                  <span className="error">{errors.companyName}</span>
                )}
              </div>
              <div className="compamydinfodivmainbtm">
                <p className="conattaindptxtppnewallasa">
                  Type of business <span>*</span>
                </p>
                <div className="dropwdowndivfrmbusineenewwlas">
                  {formData?.companyTypeID ? (
                    <p
                      style={{
                        color: "#000",
                        fontWeight: "500",
                      }}
                      className="vectoraarowdivomgulmaili"
                    >
                      {companyName}
                    </p>
                  ) : (
                    <p className="vectoraarowdivomgnenwlal">Select Company</p>
                  )}
                  <div
                    className="vectoraarowdivomg"
                    onClick={() => setToggleCompany(!toggleCompany)}
                  >
                    <img
                      src={vectorarrow}
                      style={{
                        cursor: "pointer",
                      }}
                      alt="..."
                    />
                  </div>
                  {toggleCompany && (
                    <div className="vectoraarowdivomgulmai">
                      <ul className="vectoraarowdivomgulmaiul">
                        {companies.map((item, index) => {
                          return (
                            <li
                              className="vectoraarowdivomgulmaili"
                              key={index}
                              style={{
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                handleInputChange("companyTypeID", item?._id);
                                setCompanyName(item?.type);
                                setToggleCompany(!toggleCompany);
                              }}
                            >
                              {item?.type}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
              {errors.companyTypeID && (
                <span className="error">{errors.companyTypeID}</span>
              )}
            </div>
          </div>

          {/* Contact Section */}
          <div className="eidutcommhifnnfodiv">
            <h4 className="upldbannertrtxtpp">Edit Contact</h4>
            <div className="compamydinfodivmain">
              <div className="compamydinfodivmainbtm">
                <p className="conattaindptxtppnewallasa">Telephone</p>
                <input
                  type="tel"
                  name="telephoneNo"
                  placeholder="+000-000"
                  className="inpustxocntbusuitxtppnewallattyy"
                  value={formData.telephoneNo || ""}
                  onChange={(e) =>
                    handleInputChange("telephoneNo", e.target.value)
                  }
                />
              </div>
              <div className="compamydinfodivmainbtm">
                <p className="conattaindptxtppnewallasa">
                  Mobile number <span>*</span>
                </p>
                <input
                  type="tel"
                  name="mobileNo"
                  className="inpustxocntbusuitxtppnewallattyy"
                  value={formData.mobileNo || ""}
                  onChange={(e) =>
                    handleInputChange("mobileNo", e.target.value)
                  }
                />
                {errors.mobileNo && (
                  <span className="error">{errors.mobileNo}</span>
                )}
              </div>
              <div className="compamydinfodivmainbtm">
                <p className="conattaindptxtppnewallasa">
                  WhatsApp number
                </p>
                <input
                  type="tel"
                  name="whatsappNo"
                  className="inpustxocntbusuitxtppnewallattyy"
                  value={formData.whatsappNo || ""}
                  onChange={(e) =>
                    handleInputChange("whatsappNo", e.target.value)
                  }
                />
                {errors.whatsappNo && (
                  <span className="error">{errors.whatsappNo}</span>
                )}
              </div>

              <div className="compamydinfodivmainbtm">
                <p className="conattaindptxtppnewallasa">
                  Website <span>*</span>
                </p>
                <input
                  type="text"
                  name="businessWebsiteLink"
                  placeholder="Website Link"
                  className="inpustxocntbusuitxtppnewallattyy"
                  value={formData.businessWebsiteLink || ""}
                  onChange={(e) =>
                    handleInputChange("businessWebsiteLink", e.target.value)
                  }
                />
                {errors.businessWebsiteLink && (
                  <span className="error">{errors.businessWebsiteLink}</span>
                )}
              </div>
              {/* Add more contact fields as needed */}
            </div>
          </div>

          {/* Address Section */}
          <div className="eidutcommhifnnfodiv">
            <h4 className="upldbannertrtxtpp">Edit Address</h4>
            <div className="compamydinfodivmain">
              {/* Location Field */}
              <div className="compamydinfodivmainbtm">
                <p className="conattaindptxtppnewallasa">
                  Location <span></span>
                </p>
                <input
                  type="text"
                  placeholder="Business Center"
                  name="location"
                  className="inpustxocntbusuitxtppnewallattyy"
                  value={formData.location || ""}
                  onChange={(e) =>
                    handleInputChange("location", e.target.value)
                  }
                />
              </div>

              {/* Address Field */}
              <div className="compamydinfodivmainbtm">
                <p className="conattaindptxtppnewallasa">
                  Address <span></span>
                </p>
                <input
                  type="text"
                  placeholder="Enter complete address"
                  className="inpustxocntbusuitxtppnewallattyy"
                  value={formData.address || ""}
                  onChange={(e) => handleInputChange("address", e.target.value)}
                />
              </div>

              {/* State Dropdown */}
              <div className="compamydinfodivmainbtm">
                <p className="conattaindptxtppnewallasa">
                  State<span></span>
                </p>
                <div className="dropwdowndivfrmbusineenewwlas">
                  {formData.emirate ? (
                    <p
                      className="vectoraarowdivomgulmaili"
                      style={{
                        color: "#000",
                        fontWeight: "500",
                      }}
                    >
                      {formData.emirate}
                    </p>
                  ) : (
                    <p className="vectoraarowdivomgulmaili">Select State </p>
                  )}
                  <div
                    className="vectoraarowdivomgnenwlal"
                    onClick={() => toggleDropdown("state")}
                  >
                    <img src={vectorarrow} alt="dropdown arrow" />
                  </div>
                  {activeDropdown === "state" && (
                    <div className="vectoraarowdivomgulmai">
                      <ul className="vectoraarowdivomgulmaiul">
                        {countryStates.map((state, index) => (
                          <li
                            key={index}
                            className="vectoraarowdivomgulmaili"
                            onClick={() => {
                              handleInputChange("state", state);
                              toggleDropdown("state");
                            }}
                          >
                            {state?.name}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              </div>

              {/* Country Dropdown */}
              <div className="compamydinfodivmainbtm">
                <p className="conattaindptxtppnewallasa">
                  Country <span></span>
                </p>
                <div className="dropwdowndivfrmbusineenewwlas">
                  <p
                    className="vectoraarowdivomgulmaili"
                    style={{
                      color: "black",
                      fontWeight: "500",
                    }}
                  >
                    United Arab Emirate
                  </p>
                  <div
                    className="vectoraarowdivomgnenwlal"
                    onClick={() => toggleDropdown("country")}
                  >
                    <img src={vectorarrow} alt="dropdown arrow" />
                  </div>
                  {activeDropdown === "country" && (
                    <div className="vectoraarowdivomgulmai">
                      <ul className="vectoraarowdivomgulmaiul">
                        <li className="vectoraarowdivomgulmaili">
                          United Arab Emirate
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
              </div>

              {/* Postal Code Field */}
              <div className="compamydinfodivmainbtm">
                <p className="conattaindptxtppnewallasa">
                Location Link <span></span>
                </p>
                <input
                  type="text"
                  name="businessLocationLink"
                  placeholder="Enter Location Link"
                  className="inpustxocntbusuitxtppnewallattyy"
                  value={formData.businessLocationLink || ""}
                  onChange={(e) =>
                    handleInputChange("businessLocationLink", e.target.value)
                  }
                />
              </div>
            </div>
          </div>

          {/* Save Button Section */}
          <div className="eidutcommhifnnfodiv">
            <div className="svavtatvvbtnnmddsupscftt">
              <button
                className="svavtatvvbtnnm"
                onClick={() => {
                  // Add your save logic here
                  submit();
                }}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Companyinfoeditmodal;
