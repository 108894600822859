// import React from 'react'
import { configureStore } from "@reduxjs/toolkit";
import PostSlice from '../Slice/PostSlice';
import Offer from "../Slice/Offer";
import Chat from "../Slice/Chat";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import rootReducer from "./rootReducer";
import storageSession from "redux-persist/lib/storage/session"; // Import session storage

// Persist configuration
const persistConfig = {
    key: 'root', // Key to store persisted state
    // storage,      // Specify storage engine (localStorage in this case)
    storage: storageSession, // Specify storage engine (sessionStorage in this case)
    whitelist: ['app'], // Only persist specific reducers
    blacklist: ['offer', 'chat', 'post'], // Exclude reducers
};

// Create a persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Create the store
const store = configureStore({
    reducer: persistedReducer,
});

// export const store = configureStore({
//     reducer: {
//         app: PostSlice,
//         offer: Offer,
//         chat: Chat
//     },

// });

// Persistor for managing the persistence
const persistor = persistStore(store);

export { store, persistor };