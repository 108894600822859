import React, { useContext, useEffect, useState } from "react";
import EditProfileblm from "./EditProfileblm";
import { Colorcontext } from "../../Component/Colorcontext/ColorContext";
import Blmpostadd from "./Blmpostadd";
import Viewersengagement from "./Viewersengagement";
import Upgradeprofile from "./Upgradeprofile";
import Contactdetaiilsprofile from "./Contactdetaiilsprofile";
import Uploadbannerblm from "./Uploadbannerblm";
import Aboutusblmprofile from "./Aboutusblmprofile";
import Servicesblmprofile from "./Servicesblmprofile";
import Socilallinkprofile from "./Socilallinkprofile";
import Galleryblmprofile from "./Galleryblmprofile";
import Workprogressbar from "./Workprogressbar";
import Searchkeywordprofile from "./Searchkeywordprofile";
import { useLocation } from "react-router-dom";

const Ownblmprofile = () => {
  const colordata = useContext(Colorcontext);
  const location = useLocation();
  const { formData } = location.state || {};
  const [data, setData] = useState(formData);
  useEffect(() => {
    colordata.setcolor(true);
    return () => {
      colordata.setcolor(false);
    };
  }, []);

  console.log("LINE29",formData);
  

  const updateForm = (key, value) => {
    setData((prev) => ({ ...prev, [key]: value }));
  };
  return (
    <>
      <section className="ownblmprofiledivmaoin ">
        <div className="cust_container">
          <div className="Workprogressbarmainb">
            <Workprogressbar data={data} page={3} />
          </div>

          <div className=" reposivecideforspecial">
            <div className="ownblmprofiledivleft">
              <div className="repsonsivedivcls">
                {/* For Tablet responsive code start  */}
                <div className=" oenblmsnfidivmainleft">
                  <div className="oenblmsnfdrpbapbbtm responsivdedivmain">
                    <EditProfileblm updateForm={updateForm} data={data} />
                  </div>

                  <div className="oenblmsnfdrpbapbbtm responsivdedivmain">
                    <Contactdetaiilsprofile
                      setData={setData}
                      data={data}
                      updateForm={updateForm}
                    />
                  </div>
                </div>

                <div className="oenblmsnfidivmainright">
                  <div className="oenblmsnfdrpbapbbtm responsivdedivmain ">
                    <Blmpostadd />
                  </div>
                  <div className="oenblmsnfdrpbapbbtm responsivdedivmain">
                    <Viewersengagement />
                  </div>
                  <div className="oenblmsnfdrpbapbbtm responsivdedivmain ">
                    <Upgradeprofile />
                  </div>
                  <div className="oenblmsnfdrpbapbbtm responsivdedivmain">
                    <Uploadbannerblm data={data} updateForm={updateForm} />
                  </div>
                </div>
                {/* For Tablet responsive code  end */}
              </div>

              <div className="oenblmsnfdrpbapbbtm deslrtopwdijjsmain">
                <EditProfileblm updateForm={updateForm} data={data} />
              </div>

              <div className="oenblmsnfdrpbapbbtm deslrtopwdijjsmain">
                <Blmpostadd/>
              </div>

              <div className="oenblmsnfdrpbapbbtm deslrtopwdijjsmain">
                <Viewersengagement />
              </div>

              <div className="oenblmsnfdrpbapbbtm deslrtopwdijjsmain">
                <Upgradeprofile />
              </div>
              <div className="oenblmsnfdrpbapbbtm deslrtopwdijjsmain">
                <Contactdetaiilsprofile
                  setData={setData}
                  data={data}
                  updateForm={updateForm}
                />
              </div>
            </div>
            <div className="ownblmprofiledivright">
              <div className="oenblmsnfdrpbapbbtm deslrtopwdijjsmain">
                <Uploadbannerblm data={data} updateForm={updateForm} />
              </div>
              <div className="oenblmsnfdrpbapbbtm">
                <Aboutusblmprofile data={data} updateForm={updateForm} />
              </div>

              <div className="oenblmsnfdrpbapbbtm">
                <Servicesblmprofile data={data} updateForm={updateForm} />
              </div>

              <div className="oenblmsnfdrpbapbbtm">
                <Searchkeywordprofile data={data} updateForm={updateForm} />
              </div>

              <div className="oenblmsnfdrpbapbbtm">
                <Galleryblmprofile
                  setData={setData}
                  data={data}
                  updateForm={updateForm}
                />
              </div>
              <div className="oenblmsnfdrpbapbbtm">
                <Socilallinkprofile updateForm={updateForm} data={data} />
              </div>
            </div>
          </div>

          {/* Mobile Screen Design Start */}

          <div className="mobilereposiveprofile">
            <div className="bannerprofileblmreposnive">
              <Uploadbannerblm data={data} updateForm={updateForm} />
            </div>
            <div className="mobilereposvispsicaldivflx">
              <div className="eidteprofileblmreposnive">
                <EditProfileblm updateForm={updateForm} data={data} />
              </div>

              <div className="viewsprofileprofileblmreposnive">
                <div className="">
                  <Viewersengagement />
                </div>
                <div className="bklmlposprofileprofileblmreposnive">
                  <Blmpostadd />
                </div>

                <div className="">
                  <Upgradeprofile />
                </div>
              </div>
            </div>
            <div className="contaallprofileblmreposnive">
              <Contactdetaiilsprofile
                setData={setData}
                data={data}
                updateForm={updateForm}
              />
            </div>
            <div className="contaallprofileblmreposnive">
              <Aboutusblmprofile data={data} updateForm={updateForm} />
            </div>
            <div className="contaallprofileblmreposnive">
              <Servicesblmprofile data={data} updateForm={updateForm} />
            </div>
            <div className="contaallprofileblmreposnive">
              <Galleryblmprofile
                setData={setData}
                data={data}
                updateForm={updateForm}
              />
            </div>
            <div className="contaallprofileblmreposnive">
              <Socilallinkprofile updateForm={updateForm} data={data} />
            </div>
          </div>

          {/* Mobile Screen Design End */}
        </div>
      </section>
    </>
  );
};

export default Ownblmprofile;
