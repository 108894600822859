import React, { useEffect, useState } from "react";
import editicon from "../../Images/editicon.png";
import Addservicemodal from "../../Modal/Addservicemodal";
import Addsearchkeywordmodal from "../../Modal/Addsearchkeywordmodal";
import { useSelector } from "react-redux";
import AddBusinessKeywordModal from "../../Modal/AddBusinessKeywordModal";
import "react-confirm-alert/src/react-confirm-alert.css";
import { confirmAlert } from "react-confirm-alert";

const Searchkeywordprofile = ({ data, updateForm }) => {
  console.log("KEYWORD7", data?.businessProductkeywords);

  const { UserProfileID } = useSelector((state) => state.app);
  const [aboyteditclickmodal, setAboyteditclickmodal] = useState(false);
  const [services, setServices] = useState(data?.businessProductkeywords || []);
  const [servicesIds, setServicesIds] = useState(data?.businessProductkeywordsId || []);

  const aboyteditclick = () => {
    setAboyteditclickmodal(!aboyteditclickmodal);
  };

  console.log("servicesIds19", servicesIds);


  const removeService = (indexToRemove) => {
    const updatedServices = services?.filter(
      (_, index) => index !== indexToRemove
    );
    setServices(updatedServices);
    updateForm("businessProductkeywords", updatedServices); // Update form with new services array

    const updatedServicesIds = servicesIds?.filter(
      (_, index) => index !== indexToRemove
    );
    setServicesIds(updatedServicesIds);
    updateForm("businessProductkeywordsId", updatedServicesIds); // Update form with new services array
  };

  useEffect(() => {
    setServices(data?.businessProductkeywords || [])
    setServicesIds(data?.businessProductkeywordsId || [])
  }, [data])

  const handleDeleteKeyword = (indexToRemove,item) => {
    confirmAlert({
      // title: "Confirm to delete",
      // message: `Are you sure you want to delete "${item}"?`,
      title:(
        <h4 style={{color:"#ff0000"}}>Confirm to delete keyword</h4>
      ),
      message: (
        <div>
          Are you sure you want to delete <strong>{item}</strong>?
        </div>
      ),
      buttons: [
        {
          label: "Yes",
          onClick: () => removeService(indexToRemove),
          style: {background:"#ff0000"},
        },
        {
          label: "No",
          // onClick: () => alert("Action canceled!"),
        },
      ],
    });
  };

  return (
    <>
      <div className="uploadbannerblmdiv">
        <div className="uploadbannerdivnewmain">
          <div className="consttactdetatlsarroedyrdiv">
            <p className="cinnattddttdtsttspo">Search keywords</p>
            {
              UserProfileID?._id === data?._id &&
              <div className="editeiconimg" onClick={aboyteditclick}>
                <img src={editicon} alt="..." />
              </div>
            }

          </div>
          <div className="adversytfservicexedib">
            {services?.map((item, index) => (
              <div
                key={index}
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "10px",
                  // backgroundColor: "silver",
                  padding: "5px 10px",
                  borderRadius: "15px",
                }}
              // className="servicdesdetatcxttxpp"
              >
                <p style={{ color: "#000", fontWeight: "500" }}>{item}</p>
                {
                  UserProfileID?._id === data?._id &&
                  <i
                    className="fa-solid fa-xmark delete-icon"
                    // onClick={() => removeService(index)}
                    onClick={() => handleDeleteKeyword(index,item)}
                    style={{
                      cursor: "pointer",
                      marginLeft: "10px",
                      color: "grey",
                    }}
                  ></i>
                }

              </div>
            ))}
          </div>
        </div>
      </div>

      {aboyteditclickmodal && (
        <>
          {/* <Addsearchkeywordmodal
          services={services}
          setServices={setServices}
          updateForm={updateForm}
          closemodal={setAboyteditclickmodal}
        /> */}
          <AddBusinessKeywordModal
            services={services}
            ids={servicesIds}
            setServices={setServices}
            updateForm={updateForm}
            closemodal={setAboyteditclickmodal}
          />
        </>
      )}
    </>
  );
};

export default Searchkeywordprofile;
