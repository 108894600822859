import React, { useContext, useEffect, useState } from "react";
import Workprogressbar from "./Workprogressbar";
import { Colorcontext } from "../../Component/Colorcontext/ColorContext";
import Paymenyshoppingcomp from "./Paymenyshoppingcomp";
import { useLocation } from "react-router-dom";
const Paymentpage = () => {
  const colordata = useContext(Colorcontext);
  const location = useLocation();
  const { stepsData } = location.state;
  const [data, setData] = useState(stepsData);
  console.log("LINE11a: ", data);
  useEffect(() => {
    colordata.setcolor(true);
    return () => {
      colordata.setcolor(false);
    };
  }, []);
  return (
    <>
      <section className="paymentpagesectionmain">
        <div className="cust_container">
          <div className="Workprogressbarmainb">
            <Workprogressbar page={4} />
          </div>

          <div className="shoppingcontinue">
            <div className="shoppingcontinuebg">
              <div className="">
                <Paymenyshoppingcomp data={data} setData={setData} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Paymentpage;
