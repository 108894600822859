import React, { useEffect, useState } from "react";
import { Autocomplete, TextField, Chip } from "@mui/material";
import HttpClient from "../utils/HttpClient";
import { useSelector } from "react-redux";

const AddBusinessKeywordModal = ({ closemodal, services, ids, setServices, updateForm }) => {
  const { UserProfileID } = useSelector((state) => state.app);
  const [newKeyword, setNewKeyword] = useState("");

  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleChange = (event, newValue) => {
    setSelectedOptions(newValue);
  };

  // console.log("PAGE22", services, ids);


  // const _handleChange = (e) => {
  //   setNewKeyword(e.target.value);
  // };

  const [keywordOptions, setKeywordOptions] = useState([])

  const getBusinessKeywords = async () => {
    try {
      const res = await HttpClient.requestData(
        "business-product-keywords",
        "GET"
      );

      if (res?.status) {
        const filteredData = res.data.filter(
          // (item) => !UserProfileID?.businessProductkeywords.includes(item._id)
          (item) => !ids.includes(item._id)
        );
        setKeywordOptions(filteredData || [])
      }
    } catch (error) {
      console.error("Error fetching country states:", error);
    }
  };

  useEffect(() => {
    getBusinessKeywords()
  }, [])

  const submit = () => {
    const flatSelectedOptions = selectedOptions.map((item) => item.keyword)
    const flatSelectedOptionsIds = selectedOptions.map((item) => item._id)
    const updatedKeywords = [...services, ...flatSelectedOptions];
    const updatedKeywordsIds = [...ids, ...flatSelectedOptionsIds];
    // console.log("selectedOptions63", flatSelectedOptionsIds)
    setServices(updatedKeywords);
    updateForm("businessProductkeywords", updatedKeywords);
    updateForm("businessProductkeywordsId", updatedKeywordsIds);
    // updateForm("businessProductkeywords", []);
    // updateForm("businessProductkeywordsId", []);
    // setNewKeyword("");
    closemodal(false);
  };

  return (
    <div className="edittamodalboixshadow">
      <div className="edittamodalboixsbg">
        <div className="crossdivicon" onClick={() => closemodal(false)}>
          <i className="fa-solid fa-xmark"></i>
        </div>
        <div className="edittappduudivnhhb">
          <h4 className="upldbannertrtxtpp">Add Keywords</h4>
        </div>
        <div className="abattstdovvstttschgg">
          {/* <textarea
            value={newKeyword}
            onChange={handleChange}
            rows={4}
            cols={4}
            className="addservicetextdivatreediv"
            placeholder="Enter keyword details..."
          /> */}
          <Autocomplete
            multiple
            options={keywordOptions}
            getOptionLabel={(option) => option.keyword}
            value={selectedOptions}
            onChange={handleChange}
            isOptionEqualToValue={(option, value) => option._id === value._id}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  key={option.id}
                  label={option.keyword}
                  {...getTagProps({ index })}
                />
              ))
            }
            renderInput={(params) => (
              <TextField {...params} label="Select keywords" placeholder="Type to search" />
            )}
          />
          {/* <p className="ttsrrhhshwoolumiuitstxtt">100-250 words</p> */}
          {selectedOptions?.length > 0 && (
            <div className="svavtatvvbtnnmpaggdijbtn">
              <button onClick={submit} className="svavtatvvbtnnm">
                Save
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AddBusinessKeywordModal;
