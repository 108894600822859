import React, { useEffect, useState } from "react";
import editicon from "../../Images/editicon.png";
import cameralogo from "../../Images/cameralogo.png";
import likec from "../../Images/like/like-c.png";
import { Rating } from "react-simple-star-rating";
import logoownblm from "../../Images/logoownblm.png";
import Uploadlogomodal from "../../Modal/Uploadlogomodal";
import Editlogogetmodal from "../../Modal/Editlogogetmodal";
import { useDispatch, useSelector } from "react-redux";
import StarRatings from "react-star-ratings";
import HttpClient from "../../utils/HttpClient";
import { getOwnProfile } from "../../Redux/Slice/PostSlice";
import { Link } from "react-router-dom";

const EditProfileblm = ({ updateForm, data }) => {


  const dispatch = useDispatch()

  const { UserProfileID } = useSelector((state) => state.app);
  const [rating, setRating] = useState(0);
  const [editlogomodal, seteditlogomodal] = useState(false);
  const [editlogomaodalaa, setEditlogomaodalaa] = useState(false);
  const [url, setUrl] = useState(data?.businessProfilePic || "");



  // Catch Rating value
  const handleRating = () => {
    setRating();
  };

  const editlogomodalclick = () => {
    seteditlogomodal(!editlogomodal);
  };

  const editlogoclickspcl = () => {
    setEditlogomaodalaa(!editlogomaodalaa);
  };

  const [likeCounter, setLikeCounter] = useState()
  const [isLikeByMe, setIsLikeByMe] = useState(false)

  const handleLike = async () => {
    if (UserProfileID?._id === data?._id) return
    try {
      const response = await HttpClient.requestData(
        "business-profile-like",
        "POST",
        { profileId: data?._id }
      );
      if (response.status) {
        // Navigate to payment page
        // Navigate("/searchhome");
        // Navigate("/");
        // dispatch(getOwnProfile())

        setLikeCounter(isLikeByMe ? likeCounter - 1 : likeCounter + 1)
        setIsLikeByMe(!isLikeByMe)
        console.log("RESP", response, data);

      } else {
        console.error("Error in Like/Dislike");
        console.log("RESP", response, data);
      }
    } catch (error) {
      console.error("Error submitting Like/Dislike:", error);
      console.log("RESP", error, data);
    } finally {
    }
  }

  //Follow
  const [followCounter, setFollowCounter] = useState(0)
  const [isFollowedByMe, setIsFollowedByMe] = useState(false)

  const handleFollow = async () => {
    if (UserProfileID?._id === data?._id) return
    try {
      const response = await HttpClient.requestData(
        "business-profile-follow",
        "POST",
        { profileId: data?._id }
      );
      if (response.status) {
        // Navigate to payment page
        // Navigate("/searchhome");
        // Navigate("/");
        // dispatch(getOwnProfile())

        setFollowCounter(isFollowedByMe ? followCounter - 1 : followCounter + 1)
        setIsFollowedByMe(!isFollowedByMe)
        console.log("RESP", response, data);

      } else {
        console.error("Error in Follow/Unfollow");
        console.log("RESP", response, data);
      }
    } catch (error) {
      console.error("Error submitting Follow/Unfollow:", error);
      console.log("RESP", error, data);
    } finally {
    }
  }

  useEffect(() => {
    setLikeCounter(data?.businessLikeCount || 0)
    setIsLikeByMe(data?.businessLikes.some(item => item.likedBy === UserProfileID?._id))
    setFollowCounter(data?.businessFollowCount || 0)
    setIsFollowedByMe(data?.businessFollow.some(item => item.followedBy === UserProfileID?._id))
    // console.log("DATA47", data?.businessLikes, UserProfileID?._id, isLiked);
    console.log("DATA111P", data?.businessProfilePic);
  }, [data])

  return (
    <>
      <div className="editprofileblm ">
        {
          UserProfileID?._id === data?._id && updateForm !== undefined &&
          <div className="editeiconimg" onClick={editlogoclickspcl}>
            <img src={editicon} alt="..." />
          </div>
        }



        <div className="editprofile">
          {data?.businessProfilePic ? (
            <img
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                borderRadius: "50%",
              }}
              src={data?.businessProfilePic}
              alt="..."
            />
          ) : (
            <div className="cameralogodivimgabslt">
              <div className="cameralogodivimg">
                <img src={cameralogo} alt="..." />
              </div>
              <p className="uplsdttxttppshji">Upload Logo</p>
              <p className="pixelsttxttppio">
                (Pixel Size : 000 x 000 PNG/JPEG )
              </p>
            </div>
          )}

          {/* <div className='profileimhaggemaindib'>
                        <img src={logoownblm} alt='...' />
                    </div> */}
        </div>
        <div className="">
          {/* <p className="ocensttxttpppp">{data?.personName}</p>*/}
          {/* <p className="ocensttxttpppp">{data?.companyName}</p> */}
          <p className="ocensttxttpppp"><Link
            to={`/businesspage/${UserProfileID?.companyName.toLowerCase().replace(/\s+/g, "_")}`}
            className="ocensttxttpppp _profileLink"
          >{data?.companyName}</Link></p>
          <p className="adverttstyusuppp">{data?.TypeOfCompany || data?.companyTypeName}</p>

        </div>

        <div className="likesfolwoerdivfxls">
          <span className="likstrtxttpp">{likeCounter} {likeCounter > 1 ? "Likes" : "Like"}</span>

          <span className="likstrtxttpp">{followCounter} {followCounter > 1 ? "Followers" : "Follower"}</span>
        </div>

        <div className="likedfollowdivflx">
          <button className="likedfollowlike"
            onClick={handleLike}
          >
            <img src={likec} alt="..." className="img-fluid"
              style={{
                transform: isLikeByMe ? 'scaleY(-1)' : 'none',
                transition: 'transform 0.3s ease',
              }}
            />
            {isLikeByMe ? "Dislike" : "Like"}
          </button>
          <button className="foolowndnbyhbnbtn"
            onClick={handleFollow}
          >
            {isFollowedByMe ? "Unfollow" : "Follow"}
          </button>
        </div>

        {/* For Mobile responsive design Start */}

        <div className="likesfolwoermobilrres">
          <button className="likedfollowlike">
            <img src={likec} alt="..." className="img-fluid" />
            Like
          </button>
          <span className="likstrtxttpp">0 Likes</span>
        </div>

        <div className="likesfolwoermobilrres">
          <button className="foolowndnbyhbnbtn">Follow</button>
          <span className="likstrtxttpp"> 0 followers</span>
        </div>

        {/* For Mobile responsive design End */}

        <div className="starrating">
          {/* set initial value */}
          {
            UserProfileID?.businessStatus ? (
              <Rating
                onClick={handleRating}
                initialValue={rating}
                size={30}
              />
            ) : (
              <StarRatings
                rating={0}
                starRatedColor="#F3660B"
                starEmptyColor="grey"
                numberOfStars={5}
                // name="UserProfileRating"
                starDimension="30px"
                starSpacing="5px"
                className="fa-solid fa-star"
              />
            )
          }

        </div>
      </div>

      {editlogomodal && <Uploadlogomodal closemodal={seteditlogomodal} />}

      {editlogomaodalaa && (
        <Editlogogetmodal
          updateForm={updateForm}
          setUrl={setUrl}
          url={url}
          closemodal={setEditlogomaodalaa}
        />
      )}
    </>
  );
};

export default EditProfileblm;
