import React, { useState } from "react";
import Delete from "../../src/Images/Delete.png";
import crosssimgiocn from "../../src/Images/crosssimgiocn.png";
import cameralogo from "../../src/Images/cameralogo.png";
import HttpClient from "../../src/utils/HttpClient";

const Editlogogetmodal = ({ closemodal, setUrl, url, updateForm }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState("");
  const [hasData, setHasData] = useState(false);

  const imageUpload = async (file) => {
    if (!file) return; // Ensure there is a file to upload

    setLoading(true);
    const formData = new FormData();
    formData.append("image", file);

    try {
      console.log("Uploading image:", file);
      // const res = await HttpClient.fileUplode("image-upload", "POST", formData);
      const res = await HttpClient.fileUploadWithoutAuth("image-upload", "POST", formData);
      if (res.status) {
        console.log("Image uploaded successfully:", res);
        setData(res.data.url);
        setHasData(true);
      } else {
        console.log("Error while uploading image");
      }
    } catch (error) {
      console.error("Upload failed:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      imageUpload(file);
    }
  };

  return (
    <div className="uploabannermodalbgshadow">
      <div className="editlofgogaemodalbg">
        <div className="editabannerideletcrrsdivhjdsj">
          <div className="diesdrddeytecroosdivffzl">
            <div className="dettetstyyiocnnhui">
              <img src={Delete} alt="Delete icon" />
            </div>
            <div className="dettetstyyiocnnhui" onClick={() => closemodal()}>
              <img src={crosssimgiocn} alt="Close icon" />
            </div>
          </div>
          <div>
            <p className="edittsbannerttxtppp">Edit Profile Picture</p>
          </div>
        </div>

        <div className="editprofile">
          {data ? (
            <div className="cameralogodivimgabslt">
              <div>
                <img
                  style={{
                    height: "100px",
                    width: "100px",
                    borderRadius: "50%",
                  }}
                  src={data}
                  alt="Profile logo"
                />
              </div>
            </div>
          ) : (
            <div className="cameralogodivimgabslt">
              <div className="cameralogodivimg">
                <img src={cameralogo} alt="Camera logo" />
              </div>
              <p className="uplsdttxttppshji">Upload Logo</p>
              <p className="pixelsttxttppio">
                (Pixel Size: 000 x 000 PNG/JPEG)
              </p>
            </div>
          )}
        </div>

        <div className="browoefilesabbddivforprofikeflx">
          <label className="brwdffioevbtgbjhbtnmewlogpro">
            Browse File
            <input
              type="file"
              className="inkspottsrrdiv"
              name="profilePhoto"
              onChange={handleFileChange}
              style={{ display: "none" }} // Hide the input visually
            />
          </label>
          {hasData && (
            <button
              className="svavtatvvbtnnm"
              onClick={() => {
                updateForm("businessProfilePic", data);
                setUrl(data);
                setData("");
                setHasData(false);
                closemodal();
              }}
            >
              Save
            </button>
          )}
        </div>

        {loading && (
          <div className="loader-overlay">
            <div className="loader"></div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Editlogogetmodal;
