import React from 'react'
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom'

const Viewersengagement = () => {
    const { UserProfileID } = useSelector((state) => state.app);
    return (
        <div className='viewsandebgagemntdiv '>
            <div className='viewsandebgagemntdivmain'>
                <table className='tavbedebieisdtldiv'>
                    <tr className='tavbedebieisdtldivtr'>
                        <td className='tavbedebieisdtldivtrd'>Profile viewers  </td>
                        <td className='tavbedebieisdtldivtrd'>
                            0
                        </td>
                    </tr>
                    <tr className='tavbedebieisdtldivtr'>
                        <td className='tavbedebieisdtldivtrd'>Follower engagement  </td>
                        <td className='tavbedebieisdtldivtrd'>
                            0
                        </td>
                    </tr>
                </table>
                <div className='vuisrrcannsiittxtpmaindib'>
                    {
                        UserProfileID?.businessStatus ? (
                            <Link className="vuisrrcannsiittxtp" to="/">View analytics</Link>
                        ) : (
                            <p className="vuisrrcannsiittxtp" style={{ color: "#b0beb9", cursor: "not-allowed" }}>View analytics</p>
                        )
                    }

                </div>
            </div>
        </div>
    )
}

export default Viewersengagement
