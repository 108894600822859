import { combineReducers } from 'redux';
import PostSlice from '../Slice/PostSlice';
import Offer from "../Slice/Offer";
import Chat from "../Slice/Chat";
import BusinessPostSlice from "../Slice/BusinessPostSlice"

const rootReducer = combineReducers({
    app: PostSlice,
    offer: Offer,
    chat: Chat,
    post: BusinessPostSlice,
});

export default rootReducer;
