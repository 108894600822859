import React from "react";
import PropTypes from "prop-types"; // Import PropTypes for type checking
import facebook_icon from "../../src/Images/facebook_icon.png";
import linkedin_icon from "../../src/Images/linkedin_icon.png";
import insta_icon from "../../src/Images/insta_icon.png";

const Editsocilamediamodal = ({ closemodal, data, updateForm }) => {
  const [formData, setFormData] = React.useState({
    // facebookLink: data?.facebookLink || "",
    // linkedInLink: data?.linkedInLink || "",
    // instaLink: data?.instaLink || "",
    businessFacebookLink: data?.businessFacebookLink || data?.facebookLink || "",
    linkedInLink: data?.businessLinkedInLink || data?.linkedInLink || "",
    instaLink: data?.businessInstaLink || data?.instaLink || "",
  });

  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const isValidUrl = (url) => {
    const pattern = /^(ftp|http|https):\/\/[^ "]+$/; // Simple URL regex
    return pattern.test(url);
  };

  const handleSave = () => {
    setError(""); // Reset error message
    // Validate URLs
    for (const key in formData) {
      if (!isValidUrl(formData[key]) && formData[key]) {
        setError(`Invalid URL for ${key}`);
        return;
      }
    }

    setLoading(true);
    // Loop through formData and call updateForm for each key-value pair
    Object.entries(formData).forEach(([key, value]) => {
      updateForm(key, value); // Call updateForm with key and value
    });
    setLoading(false);
    closemodal(); // Close the modal after saving
  };

  return (
    <div className="uploabannermodalbgshadow">
      <div className="editsonbscimedialsodlonkbg">
        <div className="crossdivicon" onClick={closemodal}>
          <i className="fa-solid fa-xmark" />
        </div>
        <div className="upldbantrtupldbannerdivmaindiv">
          <h4 className="upldbannertrtxtpp">Edit social link</h4>
        </div>

        {error && <p className="error-message">{error}</p>}

        <div className="sosvciidlidnbedittdivmainflxmaindiv">
          <div className="sosvciidlidnbedittdivmainflx">
            <div className="sosvciidlidnbedittdiv">
              <img src={facebook_icon} alt="Facebook" />
            </div>
            <div className="inppoiustsllonsocikk">
              <input
                name="businessFacebookLink"
                value={formData.businessFacebookLink || formData.facebookLink}
                type="text"
                className="inppoiustsllonsocikkinpy"
                onChange={handleChange}
                placeholder="Facebook Link"
                aria-label="Facebook Link"
              />
            </div>
          </div>

          <div className="sosvciidlidnbedittdivmainflx">
            <div className="sosvciidlidnbedittdiv">
              <img src={linkedin_icon} alt="LinkedIn" />
            </div>
            <div className="inppoiustsllonsocikk">
              <input
                name="businessLinkedInLink"
                value={formData.businessLinkedInLink || formData.linkedInLink}
                type="text"
                className="inppoiustsllonsocikkinpy"
                onChange={handleChange}
                placeholder="LinkedIn Link"
                aria-label="LinkedIn Link"
              />
            </div>
          </div>

          <div className="sosvciidlidnbedittdivmainflx">
            <div className="sosvciidlidnbedittdiv">
              <img src={insta_icon} alt="Instagram" />
            </div>
            <div className="inppoiustsllonsocikk">
              <input
                name="businessInstaLink"
                value={formData.businessInstaLink || formData.instaLink}
                type="text"
                className="inppoiustsllonsocikkinpy"
                onChange={handleChange}
                placeholder="Instagram Link"
                aria-label="Instagram Link"
              />
            </div>
          </div>
        </div>

        <div
          className="save-button-container"
          style={{
            display: "flex",
            margin: "10px 10px",
            justifyContent: "end",
          }}
        >
          <button
            onClick={handleSave}
            className="svavtatvvbtnnm"
            disabled={loading}
          >
            {loading ? "Saving..." : "Save Changes"}
          </button>
        </div>
      </div>
    </div>
  );
};

// Define PropTypes for type checking
Editsocilamediamodal.propTypes = {
  closemodal: PropTypes.func.isRequired,
  data: PropTypes.shape({
    facebookLink: PropTypes.string,
    linkedInLink: PropTypes.string,
    instaLink: PropTypes.string,
  }).isRequired,
  updateForm: PropTypes.func.isRequired,
};

export default Editsocilamediamodal;
