export const formatDateAgo = (date) => {
    const now = new Date();
    const target = new Date(date);

    const difference = now - target; // Difference in milliseconds
    const isPast = difference > 0; // Check if the date is in the past

    const absDifference = Math.abs(difference);

    const minutes = Math.floor(absDifference / (1000 * 60));
    const hours = Math.floor(absDifference / (1000 * 60 * 60));
    const days = Math.floor(absDifference / (1000 * 60 * 60 * 24));
    const weeks = Math.floor(absDifference / (1000 * 60 * 60 * 24 * 7));
    const months = Math.floor(absDifference / (1000 * 60 * 60 * 24 * 30.44)); // Approximate
    const years = Math.floor(absDifference / (1000 * 60 * 60 * 24 * 365.25)); // Approximate

    if (years > 0) {
        return `${years} year${years > 1 ? "s" : ""} ${isPast ? "ago" : "from now"}`;
    } else if (months > 0) {
        return `${months} month${months > 1 ? "s" : ""} ${isPast ? "ago" : "from now"}`;
    } else if (weeks > 0) {
        return `${weeks} week${weeks > 1 ? "s" : ""} ${isPast ? "ago" : "from now"}`;
    } else if (days > 0) {
        return `${days} day${days > 1 ? "s" : ""} ${isPast ? "ago" : "from now"}`;
    } else if (hours > 0) {
        return `${hours} hour${hours > 1 ? "s" : ""} ${isPast ? "ago" : "from now"}`;
    } else if (minutes > 0) {
        return `${minutes} minute${minutes > 1 ? "s" : ""} ${isPast ? "ago" : "from now"}`;
    } else {
        return `just now`;
    }
}

