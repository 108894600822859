import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Updatepostmoda from '../../Modal/Updatepostmoda'
import { useSelector } from 'react-redux';
import BusinessPostModal from '../../Modal/BusinessPostModal';

const Blmpostadd = (isActive) => {
    const { UserProfileID } = useSelector((state) => state.app);
    

    const [ownpostupdatemodal, setownpostupdatemodal] = useState(false)
    const ownnsposyymodal = () => {
        setownpostupdatemodal(!ownpostupdatemodal)
    }
    // console.log("LINE10X", isActive);

    return (
        <>
            <div className='bmlmmaposttaddddib'>
                <div className='bmlmmaposttaddddibdivmain'>
                    <p className='poiosyyattatxtpp'>
                        Post your latest product or service
                        to connect your customers.
                    </p>
                    <div className='poisyybtnmainlibkldivflx'>
                        <button
                            className='poisyybtnmain'
                            onClick={ownnsposyymodal}
                            disabled={!UserProfileID?.businessStatus}
                        >
                            Post
                            <i className="fa-solid fa-plus"></i>
                        </button>
                        <div className=''>
                            {
                                UserProfileID?.businessStatus ? (
                                    <Link to="/ownpostpage" className="viewallpostlnk">View all posts</Link>
                                ) : (
                                    <p className="viewallpostlnk" style={{ color: "#b0beb9", cursor: "not-allowed" }}>View all posts</p>
                                )
                            }

                        </div>
                    </div>
                </div>
            </div>
            {/* {ownpostupdatemodal && <Updatepostmoda closemodal={setownpostupdatemodal} />} */}
            {ownpostupdatemodal && <BusinessPostModal closemodal={setownpostupdatemodal} />}
        </>
    )
}

export default Blmpostadd
